import React from "react"

import SEO from "../components/seo"
import "../css/theme.css"
import "../css/layout.css"

const NotFoundPage = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      justifyContent: "center",
      padding: "0 30px",
      textAlign: "center"
    }}
  >
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </div>
)

export default NotFoundPage
